import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	'components': {
		'app': {
			'children': {
				'debugInfoAction': {},
				'wrapper': {},
			},
		},
	},
	'theme': {
		'base': {
			'card': {
				'children': {
					'body': {
						'overrides': [
							{
								'selector': 'ul',
								'style': {
									'margin': {
										'_value': '0',
									},
									'padding': {
										'_value': '0 0 0 1.2em',
									},
								},
							},
							{
								'selector': 'li',
								'style': {
									'margin': {
										'_value': '0 0 1em 0',
									},
								},
							},
							{
								'selector': 'li::marker',
								'style': {
									'color': '#0070C1',
								},
							},
						],
					},
					'subtitle': {
						'font': {
							'family': 'Heebo, sans-serif',
							'size': '24px',
							'weight': '300',
						},
					},
					'title': {
						'font': {
							'size': '24px',
							'weight': '500',
						},
						'margin': {
							'_value': '0 0 0.5em 0',
						},
					},
					'wrapper': {
						'margin': {
							'bottom': '0',
						},
					},
				},
			},
			'heading': {
				'children': {
					'h1': {
						'font': {
							'size': '32px',
							'weight': '500',
						},
						'margin': {
							'_value': '0 0 0.5em 0',
						},
					},
					'h2': {
						'font': {
							'size': '24px',
							'weight': '400',
						},
						'margin': {
							'_value': '0 0 1em 0',
						},
					},
					'h3': {
						'breakpoints': [
							{
								'max': '767px',
								'style': {
									'margin': {
										'bottom': '8px',
									},
								},
							},
						],
						'color': '#001928',
						'font': {
							'family': 'Heebo, sans-serif',
							'size': '24px',
							'style': 'normal',
							'weight': '400',
						},
						'lineHeight': '29px',
						'margin': {
							'_value': '0 0 16px 0',
						},
					},
				},
			},
			'text': {
				'children': {
					'content': {
						'color': '#001928',
						'font': {
							'family': 'Heebo, Arial',
							'size': '16px',
							'style': 'normal',
							'weight': '400',
						},
						'lineHeight': '24px',
					},
				},
				'overrides': [
					{
						'selector': '[data-selector="base-text-textSpan"] p, [data-selector="base-text-textSpan"] ol li',
						'style': {
							'color': '#001928',
							'font': {
								'family': 'Heebo, Arial',
								'size': '16px',
								'style': 'normal',
								'weight': '400',
							},
							'lineHeight': '24px',
						},
					},
					{
						'selector': '[data-selector="base-text-textSpan"] span',
						'style': {
							'background': {
								'color': 'rgba(0,0,0,0) !important',
							},
						},
					},
				],
			},
		},
	},
};