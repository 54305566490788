import React, { Component } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import Wrapper from './styledComponents/Wrapper';
import DebugInfoAction from '../shared/DebugInfoAction';
import NoDataPlaceholder from '@kurtosys/ksys-app-components/dist/components/base/NoDataPlaceholder';
import Disclaimer from '@kurtosys/ksys-app-components/dist/components/overview/Disclaimer';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';
import Card from '@kurtosys/ksys-app-components/dist/components/base/Card';
import Text from '@kurtosys/ksys-app-components/dist/components/base/Text';
import Actions from '../Actions';

@inject('appStore', 'queryStore')
@observer
class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	render() {
		const { className, appStore, queryStore } = this.props;
		if (!appStore || !queryStore) {
			return null;
		}
		const { isBootstrapped, skeletonLoader, skeletonLoaders, dataContextStatus } = appStore;
		if (!isBootstrapped || dataContextStatus === 'PENDING') {
			const { embedLoadingElement } = this.props;

			if (skeletonLoader && !embedLoadingElement) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading embedLoadingElement={ embedLoadingElement } />;
		}
		const { textProps, theme, show, showPlaceholder, noDataPlaceholder: noDataPlaceholderText, noDataPlaceholderDisclaimer, libraryComponentsConfiguration, appCardProps, getTranslateFunction, culture, assets, assetCacheOptions, assetRegisters, getAccessibilityTextFunction } = appStore;

		if (!show) {
			return null;
		}

		const noDataPlaceholder =
			(noDataPlaceholderDisclaimer && <Disclaimer { ...noDataPlaceholderDisclaimer } />) ||
			(noDataPlaceholderText && <NoDataPlaceholder>{ noDataPlaceholderText }</NoDataPlaceholder>);

		const translate = getTranslateFunction();
		const accessibilityText = getAccessibilityTextFunction();

		return (
			<Application theme={ theme } configuration={ libraryComponentsConfiguration } translate={ translate } culture={ culture } assets={ assets } assetCacheOptions={ assetCacheOptions } assetRegisters={ assetRegisters } accessibilityText={ accessibilityText } analyticsHelper={ appStore.analyticsHelper }>
				<DebugInfoAction appStore={ appStore } />
				<Wrapper className={ className }>
					<Card { ...appCardProps }>
						{ showPlaceholder && noDataPlaceholder }
						{ !showPlaceholder && (
							<>
								<Text { ...textProps } />
								<Actions />
							</>
						) }
					</Card>
				</Wrapper>
			</Application>
		);
	}
}

export { App };
export default InjectedStyledComponent(App, 'app');
