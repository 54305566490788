import { TemplateStore } from '../components/App/stores/TemplateStore';
import { AppStore } from '../components/App/stores/AppStore';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';
import { TranslationStore } from '../components/App/stores/TranslationStore';
import { QueryStore } from '../components/App/stores/QueryStore';
import { Manifest } from './Manifest';
import { ActionsStore } from '../components/Actions/stores/ActionsStore';
import { AccessibilityStore } from '../components/App/stores/AccessibilityStore';
import { InputStore } from '../components/App/stores/InputsStore';
import { EventBusStore } from '../components/App/stores/EventBusStore';
import { FetchAndQueryStore } from '../components/App/stores/FetchAndQueryStore';
/* [Component: storesImport] */

export class StoreContext {
	templateStore: TemplateStore;
	appStore: AppStore;
	kurtosysApiStore: KurtosysApiStore;
	translationStore: TranslationStore;
	queryStore: QueryStore;
	actionsStore: ActionsStore;
	accessibilityStore: AccessibilityStore;
	inputStore: InputStore;
	eventBusStore: EventBusStore;
	fetchAndQueryStore: FetchAndQueryStore;
	/* [Component: storeVariable] */
	constructor(element: HTMLElement, url: string, templateStore: TemplateStore, manifest: Manifest) {
		this.templateStore = templateStore;
		this.kurtosysApiStore = new KurtosysApiStore(this);
		this.translationStore = new TranslationStore(this);
		this.queryStore = new QueryStore(this);
		this.inputStore = new InputStore(this);
		this.eventBusStore = new EventBusStore(this);
		this.appStore = new AppStore(element, url, this, manifest);
		this.actionsStore = new ActionsStore(this);
		this.accessibilityStore = new AccessibilityStore(this);
		this.fetchAndQueryStore = new FetchAndQueryStore(this);
		/* [Component: storeAssignment] */
		this.initialize();
	}
	initialize() {
		this.appStore.initialize();
	}
	reInitialize() {
		this.appStore.reInitialize();
	}
	get providerProps() {
		return {
			appStore: this.appStore,
			kurtosysApiStore: this.kurtosysApiStore,
			translationStore: this.translationStore,
			queryStore: this.queryStore,
			actionsStore: this.actionsStore,
			accessibilityStore: this.accessibilityStore,
			inputStore: this.inputStore,
			eventBusStore: this.eventBusStore,
			/* [Component: storeProviderProps] */
		};
	}

	// TODO: NEED TO MERGE STORES
	registerStore(key: string, store: any) {

	}
}