import { query, utils } from '@kurtosys/ksys-app-template';
import { StoreContext } from '../configuration/StoreContext';
import { Manifest } from '../configuration/Manifest';
import { observable } from 'mobx';
import { IConfigurationComponentOrchestration } from '../models/app/IConfigurationComponentOrchestration';
import { IExecutionOptions, IQueryOptions } from '../models/commonTypes';
import { IOverridingStoreOptions } from '../models/app/IOverridingStoreOptions';

export abstract class StoreBase {
	storeContext: StoreContext;
	manifest: Manifest;
	@observable isInitialized: boolean = false;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
		this.manifest = new Manifest();
	}
	abstract initialize(callbacks: Record<string, EventListener> | undefined): Promise<void>;

	getQueryValue(queryOptions?: IQueryOptions, responseForContext?: object, overrideExecutionOptions?: Partial<IExecutionOptions>, overridingOptions: IOverridingStoreOptions = {}) {
		if (queryOptions) {
			const queryStore = this.getQueryStore(overridingOptions);
			const responseContextExecutionOptions: Partial<IExecutionOptions> = {
				responseContext: responseForContext,
			};
			overrideExecutionOptions = utils.object.deepMergeObjectsWithOptions(
				{ arrayMergeStrategy: 'DeepMerge' },
				responseContextExecutionOptions,
				overrideExecutionOptions || {},
			);
			return queryStore.query(queryOptions, overrideExecutionOptions);
		}
		return;
	}

	mergeQueriesAndProps<T>(componentOrchestration?: IConfigurationComponentOrchestration, responseContext?: any, overridingOptions: IOverridingStoreOptions = {}): T | undefined {
		if (componentOrchestration) {
			const queryStore = this.getQueryStore(overridingOptions);
			const { translationStore } = this.storeContext;
			const { props, queries } = componentOrchestration;
			const translationHelper = translationStore && translationStore.translationHelper;
			let workingExecutionOptions = queryStore.executionOptions;
			if (responseContext) {
				workingExecutionOptions = {
					...workingExecutionOptions,
					responseContext,
				};
			}
			const queryResults = queries ? query.Query.convertQueryMime(queries, workingExecutionOptions, { translationHelper }) : {};
			const response: T = utils.object.deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, props || {}, queryResults);
			return response;
		}
	}

	getQueryStore(overridingOptions: IOverridingStoreOptions) {
		const { queryStore } = this.storeContext;
		const { queryStore: queryStoreOverride } = overridingOptions;
		return queryStoreOverride || queryStore;
	}

}