import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	components: {
		app: {
			cardProps: {
				queries: {},
				props: {
					title: "Portfolio Strategy",
					titleLevel: 3
				}
			},
			commentary: {
				entityType: 'CLSS',
				key: 'portfolio_strategy',
				queryOptionsType: 'commentaries',
			},
			noDataOptions: {
				hide: true
			},
			collapsedText: {
				queryOptionsType: "none",
				value: 'The is the <b>collapsed</b> text'
			},
			textProps: {
				hasHtmlContent: true,
				value: "",
				collapse: {
					maxLength: 100,
					suffix: "...",
				},
				toggleState: "collapsed"
			},
			title: {
				queryOptionsType: "none",
				value: "Portfolio Strategy"
			}
		}
	}
};
