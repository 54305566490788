import { models, helpers, utils } from '@kurtosys/ksys-app-template';
import { action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IGlobalInputsChangeDetail, IGlobalSearchChangeDetail } from '../../../models/commonTypes';

export class InputStore extends StoreBase {

	@computed
	get globalInputIdentifiers(): string[] {
		return this.storeContext.appStore.globalInputIdentifiers;
	}

	public initialize(): Promise<void> {
		throw new Error('Method not implemented.');
	}

	public getStorageId(rawIdentifier: string) {
		return `${ rawIdentifier }-${ this.storeContext.appStore.currentUrlKey }`;
	}

	/**
	 * This method helps initialize the query store context with inputs based on prior selections in the associated global input apps.
	 */
	public async getAllFromStorage() {
		const identifiers = [];
		let storageValues = {};
		// build up one object of inputs for all inputs we are listening for
		for (const identifier of this.globalInputIdentifiers) {
			this.storeContext.appStore.logDebug('Input Store > Get All From Storage', identifier);
			const values = await this.getFromStorage(identifier);
			if (!utils.typeChecks.isNullOrEmpty(values)) {
				identifiers.push(identifier);
				storageValues = {
					...storageValues,
					...values,
				};
			}
		}
		this.cleanValues(storageValues);
		return storageValues;
	}

	/**
	 * Updates the query store's context with new inputs, thus re-firing requests
	 *
	 * @param identifiers
	 * @param values
	 */
	@action
	private async updateQueryInputs(identifiers: string[], values: Record<string, any>) {
		this.cleanValues(values);
		await this.storeContext.queryStore.updateInputs(values);
	}

	/**
	 * This method will search for global inputs values based on events received from a global input app, and trigger the updates to app state.
	 * 
	 * Note: this handler will only execute if the event is within the listenFor / globalInputsIdentifiers is configured. (Handler implemented in EventBusStore.initialize as a default)
	 *
	 * @param identifier
	 * @param [storageType]
	 * @param [values]
	 * @returns
	 */
	public handleUpdate = async (customEvent: CustomEvent<IGlobalInputsChangeDetail>) => {
		const { eventBusStore } = this.storeContext;
		const { storageType, values } = customEvent.detail;
		const identifier = eventBusStore.getSourceAppIdFromEvent(customEvent);
		// sync values passed from the event
		if (values) {
			await this.updateQueryInputs([identifier], values);
		}
		// sync values from storage
		else {
			const storageValues = this.getFromStorage(identifier, storageType);
			if (storageValues) {
				await this.updateQueryInputs([identifier], storageValues);
			}
		}
	}

	public handleSearchUpdate = async (customEvent: CustomEvent<IGlobalSearchChangeDetail>) => {
		const { eventBusStore } = this.storeContext;
		const { searchTerm } = customEvent.detail;
		const identifier = eventBusStore.getSourceAppIdFromEvent(customEvent);
		// sync values passed from the event
		await this.updateQueryInputs([identifier], { searchTerm });
	}

	/**
	 * Get the global input out of storage, and apply fallback searches
	 *
	 * @param identifier
	 * @param [storageType=models.eventBus.StorageType.Session]
	 * @returns
	 */
	private getFromStorage(identifier: string, storageType: models.eventBus.StorageType = models.eventBus.StorageType.Session): Record<string, any> | undefined {
		let storage;
		switch (storageType) {
			case models.eventBus.StorageType.Local:
				storage = localStorage;
				break;
			case models.eventBus.StorageType.Session:
				storage = sessionStorage;
				break;
			default:
				return;
		}
		const storageValues = this.getStorageValue(storage, identifier);

		// As we do not know how the global input app is setup to store its values when initializing the current app, we have to look at the default session storage, and fallback to local storage.
		if (!storageValues && storageType === models.eventBus.StorageType.Session) {
			return this.getFromStorage(identifier, models.eventBus.StorageType.Local);
		}

		return storageValues;
	}

	/**
	 * Pull global input value out of the target storage type, and convert it into an object.
	 *
	 * @param storage
	 * @param rawIdentifier
	 * @returns
	 */
	private getStorageValue(storage: Storage, rawIdentifier: string): Record<string, any> | undefined {
		let storageValue = storage.getItem(this.getStorageId(rawIdentifier));
		if (!storageValue) {
			// fallback to legacy storage key where the URL was not considered
			storageValue = storage.getItem(rawIdentifier);
		}
		if (storageValue) {
			try {
				return JSON.parse(storageValue);
			}
			catch (e) {
				this.storeContext.appStore.logDebug('Input Store > Get Storage Value Error', e);
			}
		}
		return;
	}

	/**
	 * The global inputs app has the option to store an object with a label and value to help identify which item to pre-select
	 * This method parses the object and only returns the value to assist with input placeholders e.g. {input:date}
	 *
	 * @private
	 * @param [values]
	 */
	private cleanValues(values?: Record<string, any>) {
		if (values) {
			for (const key of Object.keys(values)) {
				if (values[key] && typeof values[key] === 'object' && values[key].label && values[key].value) {
					values[key] = values[key].value;
				}
			}
		}
	}
}